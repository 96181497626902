.dropdown {
  position: relative;
  .toggle-dropdown {
    background: none;
    font-size: 14px;
    min-width: inherit;
    padding: 10px 30px 10px 0;
    position: relative;
    line-height: 16px;
    text-align: left;
    font-family: $NHU-reg;
    width: 100%;
    strong {
      display: block;
      margin-bottom: 2px;
      font-family: $NHU-bold;
    }
    span {
      font-size: 13px;
    }
    &::after {
      @include transforms(translateY(-50%));
      position: absolute;
      top: 50%;
      right: 0;
      content: "\e874";
      font-family: $lnr;
    }
    .img {
      width: 43px;
      height: 43px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 12px;
      vertical-align: middle;
      + .inner {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .content-dropdown {
    @include transforms(translateY(15px));
    @include transition(all .3s);
    box-shadow: 0 0 11px #e8e8e8;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    position: absolute;
    top: 100%;
    min-width: 220px;
    padding: 1px 15px;
    left: 0;
    text-align: left;
    z-index: 12;
    ul {
      strong {
        display: block;
        margin-bottom: 3px;
        font-family: $NHU-bold;
      }
      span {
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
      }
      li {
        border-bottom: 1px solid $color-border;
        &:last-child {
          border-bottom: 0;
        }
      }
      a {
        padding: 16px 0;
        display: block;
        line-height: 16px;
      }
      .lnr {
        font-size: 16px;
        margin-right: 10px;
      }
      .icon {
        width: 15px;
        background-size: 15px;
        margin-right: 7px;
      }
    }
  }
  &.active {
    .content-dropdown {
      @include transforms(translateY(0));
      opacity: 1;
      visibility: visible;
    }
  }
  &.appear-right {
    .content-dropdown {
      left: auto;
      right: 0;
    }
  }
  &.drop-border {
    .toggle-dropdown {
      border: 1px solid $color-border-dark;
      border-radius: 0;
      padding: 9px 42px 9px 11px;
      color: #595959;
      &:after {
        right: 10px;
        font-size: 10px;
      }
      &:before {
        @include transforms(translateY(-50%));
        position: absolute;
        height: 19px;
        border-left: 1px solid #e6e6e6;
        right: 30px;
        top: 50%;
        content: "";
      }
    }
  }
  .drop-two-col {
    @extend %clearfix;
    @include flexbox();
    .col {
      width: 50%;
      float: left;
      min-width: 193px;
      margin: 25px 8px;
    }
    .col-2 {
      border-left: 1px solid $color-border;
      padding-left: 20px;
    }
    .status {
      label {
        display: block;
        margin-bottom: 7px;
      }
      .label {
        min-width: 70px;
      }
    }
    .title-col {
      font-size: 13px;
      font-family: $NHU-bold;
      margin-bottom: 15px;
    }
    .checkbox {
      span {
        &::before {
          top: 1px;
        }
      }
    }
    .by-date {
      .to {
        margin: -2px 0 14px;
        display: block;
      }
      .input-box {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media screen and (max-width: $small) {
  .dropdown {
    .drop-two-col {
      display: block;
      padding: 10px 0;
      .col {
        width: 100%;
        margin: 0;
        padding: 10px;
      }
      .col-2 {
        border: 0;
      }
    }
  }
}