.label {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
  border-radius: 3px;
  background: #777;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  font-family: $NHU-reg;
  text-align: center;
  white-space: nowrap;
  &.label-danger {
    background: $color-red;
  }
  &.label-success {
    background: $color-green;
  }
  &.label-warning {
    background: #f0ad4e;
  }
  &.label-info {
    background: #5bc0de;
  }
}