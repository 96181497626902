.toolbar {
  padding: 18px 0;
  &::after {
    @extend %clearfix;
  }
  .filter-by {
    .dropdown {
      min-width: 246px;
    }
  }
  .goto {
    display: inline-block;
    vertical-align: middle;
    input {
      width: 63px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .pages-control {
    display: inline-block;
    vertical-align: middle;
  }
  .select:not(.select-border) {
    min-width: 150px;
  }
  .dropdown {
    display: inline-block;
    vertical-align: middle;
  }
  .log {
    padding-top: 6px;
    > * {
      display: inline-block;
      vertical-align: middle;
    }
    .label {
      margin-right: 60px;
    }
  }
  .title-toolbar {
    font-size: 16px;
    font-family: $NHU-bold;
  }
}
.toolbar-bottom {
  border-bottom: 1px solid $color-border-dark;
}
.toolbar-top {
  border-top: 1px solid $color-border-dark;
}
.pages-control {
  border: 1px solid $color-border-dark;
  font-size: 0;
  a {
    display: inline-block;
    font-size: 13px;
    padding: 10.5px;
    min-width: 49px;
    background: #f2f2f2;
    border-right: 1px solid $color-border-dark;
    text-align: center;
    &:last-child {
      border-right: 0;
    }
    &:hover {
      background: $color-primary;
      color: #000;
    }
  }
}

@media screen and (max-width: $wide) {
  .toolbar {
    .pages {
      > span {
        display: none;
      }
    }
    .goto,
    .number-page {
      display: none;
    }
  }
}
@media screen and (max-width: 700px) {
  .toolbar {
    .filter-by {
      width: 100%;
      padding-top: 18px;
      > strong {
        display: none;
      }
      .dropdown {
        width: 100%;
      }
      .dropdown.appear-right {
        .content-dropdown {
          left: 0;
        }
      }
    }
    .pages {
      + .pages {
        float: right;
      }
    }
    &.toolbar-bottom {
      .pages {
        + .pages {
          float: left;
        }
      }
    }
  }
}
@media screen and (max-width: $medium) {
  .toolbar {
    &.pdt-0 {
      padding-top: 18px !important;
    }
    .mgr-30 {
      margin-right: 15px;
    }
  }
}
@media screen and (max-width: $small) {
  .toolbar {
    &.toolbar-bottom {
      .pages {
        + .pages {
          display: none;
        }
      }
    }
  }
}