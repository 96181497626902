.section-border {
  border-bottom: 1px solid $color-border;
  border-top: 1px solid $color-border;
  margin-bottom: -1px;
  padding: 30px 0;
  &::after {
    @extend %clearfix;
  }
  .title-setting {
    h3 {
      font-family: $NHU-bold;
      letter-spacing: -0.025em;
      font-size: 16px;
      margin-bottom: 14px;
    }
  }
  .col {
    float: left;
  }
  .col-1 {
    width: 35%;
    padding-right: 15px;
  }
  .col-2 {
    width: 65%;
  }
  form {
    textarea {
      height: 337px;
    }
  }
}
@media screen and (max-width: $medium) {
  .section-border {
    .col-1 {
      width: 100%;
      margin-bottom: 30px;
    }
    .col-2 {
      width: 100%;
    }
  }
}