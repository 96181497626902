button,
.btn {
  @include transition(background .4s);
  font-size: 16px;
  text-align: center;
  border: 0;
  border-radius: 5px;
  color: $color-black;
  padding: 15px 10px;
  background: $color-primary;
  min-width: 209px;
  cursor: pointer;
  font-family: $NG-bold;
  letter-spacing: -0.025em;
  &:focus {
    outline: none;
  }
  &:hover {
    background: #f1c306;
    color: #000;
  }
}
.btn-no-style {
  background: none!important;
  min-width: initial;
  padding: 0;
  .lnr {
    font-size: 19px;
  }
  &:hover {
    .lnr {
      color: $color-primary;
    }
  }
}