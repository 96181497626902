table {
  width: 100%;
  border-top: 1px solid #d4d4d4;
  tr {
    border-bottom: 1px solid $color-border;
  }
  td,
  th {
    padding: 15.5px 10px;
    line-height: 22px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      text-align: right;
    }
  }
  th {
    font-family: $NHU-bold;
    padding: 19px 10px;
  }
  .btn-no-style {
    + .btn-no-style {
      margin-left: 10px;
    }
  }
  .label {
    min-width: 70px;
    text-align: center;
  }
  .drop-border {
    min-width: 128px;
    margin-top: -10px;
    margin-bottom: -10px;
  }
}
.table-vertop {
  td {
    vertical-align: top;
  }
  .drop-border {
    margin-top: 0;
  }
}

@media screen and (max-width: $medium) {
  table {
    th {
      white-space: nowrap;
    }
    td:last-child {
      white-space: nowrap;
    }
  }
  .table-responsive {
    overflow-y: hidden;
  }
}