body {
  font-size: 14px;
  font-family: $NHU-reg;
  color: $color-black;
  line-height: 1;
}
p {
  line-height: 24px;
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
img {
  max-width: 100%;
}
a {
  @include transition(all .4s);
  text-decoration: none;
  color: $color-black;
  &:hover {
    color: $color-primary;
  }
}
strong {
  font-family: $NHU-bold;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.025em;
}