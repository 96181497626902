.block-service-logs-view {
  .table {
    th,
    td {
      &:last-child {
        text-align: left;
      }
    }
  }
}
@media screen and (max-width: 856px) {
  .block-service-logs-view {
    .toolbar {
      .log {
        > * {
          display: inline;
        }
        .label {
          margin-right: 10px;
        }
      }
      .filter-by {
        > strong {
          display: none;
        }
        .dropdown {
          min-width: 150px;
        }
      }
    }
  }
}