#page-main {
  padding: 3%;
}
.max-width-content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.empty-content {
  text-align: center;
  padding-top: 100px;
  img {
    margin-bottom: 50px;
  }
  .icon {
    font-size: 150px;
    margin-bottom: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  } 
  h3 {
    margin-bottom: 20px;
    font-family: $NG-bold;
    font-size: 28px;
  }
  p {
    color: #6f6f6f;
    max-width: 620px;
    margin: 0 auto;
  }
  .buttons-set {
    .btn {
      max-width: 352px;
      margin: 0 auto;
      width: 100%;
      display: block;
      height: 56px;
      padding: 20px 10px;
    }
  }
  .line-border {
    max-width: 900px;
    margin: 40px auto 60px;
  }
}
@media screen and (max-width: $small) {
  .empty-content {
    padding-top: 50px;
  }
}