.tooltip-data {
  position: relative;
  &::after {
    @include transforms(translateX(-50%));
    @include transition(all .4s);
    content: attr(data-tooltip);
    font-family: $NHU-reg;
    font-size: 13px;
    padding: 11px 10px;
    border-radius: 4px;
    box-shadow: 0 3px 7px rgba(0,0,0,.18);
    position: absolute;
    bottom: 100%;
    left: 50%;
    background: #fff;
    z-index: 12;
    min-width: 66px;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 3px;
    opacity: 0;
    visibility: hidden;
  }
  &::before {
    @include transforms(translateX(-50%));
    @include transition(all .4s);
    position: absolute;
    border-right: 6px solid transparent;
    border-top: 6px solid #fff;
    border-left: 6px solid transparent;
    content: '';
    bottom: 100%;
    z-index: 13;
    margin-bottom: -3px;
    left: 50%;
    visibility: hidden;
    opacity: 0;
  }
  &:hover {
    &::after,
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }
}