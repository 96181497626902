@mixin border-radius($radius) {
  @include pxRem('-webkit-border-radius', $radius);
  @include pxRem('-moz-border-radius', $radius);
  @include pxRem('-o-border-radius', $radius);
  @include pxRem('border-radius', $radius);
}
@mixin transforms($attributes) {
  -webkit-transform: $attributes;
  -moz-transform: $attributes;
  -o-transform: $attributes;
  transform: $attributes;
}
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}
@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
          align-items: $align;
}
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
     -moz-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}
@mixin placeholderCl($val) {
  &::-webkit-input-placeholder {
    color: map-get($color, $val);
  }
  &::-moz-placeholder {
    color: map-get($color, $val);
  }
  &:-ms-input-placeholder {
    color: map-get($color, $val);
  }
  &:-moz-placeholder {
    color: map-get($color, $val);
  }
}
// ==Convert Px To Rem
@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function convertRootPxToEm($value) {
  // check for null:
  @if($value == null) {
    @return $value;
  }
  // assume em:
  @if (unitless($value) or unit($value) == em) {
    @return 0em + $value;
  }
  @else {
    @return 0em + strip-units($value)/16;
  }
}
@function convertPxToRem($px) {
 @return 0rem + strip-units($px)/16;
}
@mixin pxRem($property,$values) {
  // Create a couple of empty lists as output buffers:
  $px-values: ();
  $rem-values: ();

  // Ensure $values is a list.
  @if type-of($values) != 'list' {
    $values: join((), $values);
  }

  // Loop through the $values list:
  @each $value in $values {
    @if type-of($value) == number and (unitless($value) or unit($value) == px) {
    $px-values: join($px-values, 0px + ($value));
      $rem-values: join($rem-values, convertPxToRem($value));
    }
    @else {
    // no conversion:
    $px-values: join($px-values, $value);
    $rem-values: join($rem-values, $value);
    }
  }

  #{$property}: $px-values;

  // don't add media query if it's not needed:
  @if $px-values != $rem-values {
    // @media only all {
      #{$property}: $rem-values;
    // }
  }
}
// == End / Convert Px To Rem

//== keyframes
@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ', '};
    }
  }
  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations;
}
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}
@mixin s320 {
  @media (max-width: 360px) {
    @content;
  }
}
