.list-chart-small {
  @extend %clearfix;
  margin: 0 -1%;
  .item {
    width: 20%;
    float: left;
    padding: 0 1%;
  }
  .title {
    font-size: 14px;
    font-family: $NHU-bold;
    margin-bottom: 10px;
  }
  .number {
    margin-bottom: 10px;
    font-size: 22px;
    font-family: $NHU-bold;
  }
}
.chart-line-and-circle {
  @extend %clearfix;
  margin-bottom: 45px;
  .chart-line-dot {
    width: 65.5%;
    float: left;
    margin-right: 3%;
  }
  .chart-circle {
    width: 31.5%;
    float: left;
  }
}
.chart-circle {
  .inner-chart {
    padding-left: 157px;
    position: relative;
    margin-top: 13px;
  }
  .labels {
    position: absolute;
    left: 0;
    top: 0;
    span {
      display: block;
      font-size: 13px;
      font-family: $NHU-bold;
      margin-bottom: 12px;
      i {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        vertical-align: middle;
        margin-top: -2px;
      }
    }
  }
}
@media screen and (max-width: $widest + 1) {
  .chart-line-and-circle {
    .chart-line-dot {
      width: 55%;
    }
    .chart-circle {
      width: 42%;
    }
  }
}
@media screen and (max-width: $wide) {
  .chart-line-and-circle {
    .chart-line-dot {
      width: 100%;
      margin-right: 0;
      padding-bottom: 30px;
    }
    .chart-circle {
      width: 100%;
    }
  }
  .list-chart-small {
    .item {
      width: 50%;
      margin-bottom: 40px;
    }
  }
}
@media screen and (max-width: $medium) {
  .chart-line-and-circle {
    .chart-line-dot,
    .chart-circle {
      border-bottom: 1px solid $color-border;
      padding-bottom: 40px;
      margin-bottom: 10px;
    }
    .chart-circle {
      margin-bottom: 0;
      .select {
        margin-top: 2px;
        select {
          padding-right: 22px;
          font-size: 13px;
        }
      }
    }
  }
  .list-chart-small {
    margin: 0 -3%;
    .item {
      padding: 0 3%;
    }
  }
}