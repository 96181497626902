$lnr: 'Linearicons-Free';

// Font Family
$NG-bold: 'NeuzeitGro W01 Bold',helvetica,arial,sans-serif;
$NHU-reg: 'Neue Haas Unica W01 Regular',helvetica,arial,sans-serif;
$NHU-hea: 'Neue Haas Unica W01 Hea1492559',helvetica,arial,sans-serif;
$NHU-bol: 'Neue Haas Unica W01 Bol1492557',helvetica,arial,sans-serif;
$NHU-bold: 'Neue Haas Unica W01 Bold',helvetica,arial,sans-serif;
$NGB-reg: 'NeuzeitGroBla W01 Regular',helvetica,arial,sans-serif;
$NGtesk-reg:'Neuzeit Grotesk W01 Reg1487164',helvetica,arial,sans-serif;
// Color
$color-primary: #ffce00;
$color-border: #eeeeee;
$color-border-dark: #d4d4d4;
$color-black: #222222;
$color-gray: #f6f6f6;
$color-green: #00a65a;
$color-red: #e13445;
// Size
$widest: 1439px;
$wide: 1199px;
$medium: 992px;
$small: 479px;

