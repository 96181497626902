.block-db {
  margin-bottom: 60px;
  .title-block {
    font-size: 28px;
    letter-spacing: -0.025em;
    margin-bottom: 40px;
    font-family: $NG-bold;
    .btn {
      margin-top: -10px;
    }
  }
}
@media screen and (max-width: $medium) {
  .block-db {
    margin-bottom: 0;
    border-bottom: 1px solid $color-border;  
    .title-block {
      text-align: center;
      margin: 0;
      padding: 44px 0 40px;
      border-bottom: 1px solid $color-border;  
      .btn {
        float: none;
        display: block;
        margin: 26px auto 0;
        max-width: 300px;
      }
    }
    &:last-of-type {
      border-bottom: 0;
    }
  }
}