header {
  @extend %clearfix;
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 0 11px #e8e8e8;
  min-height: 84px;
  padding-right: 25px;
  top: 0;
  .logo {
    background: $color-primary;
    float: left;
    width: 64px;
    text-align: center;
    margin-right: 28px;
    height: 84px;
    padding-top: 28px;
  }
  .drop-gymShark {
    float: left;
    min-width: 240px;
    margin-top: 16px;
    .content-dropdown {
      right: 0;
    }
  }
  .drop-user {
    float: right;
    margin-top: 11px;
    min-width: 175px;
  }
  ~ #page-main {
    margin-top: 84px;
  }
  .toggle-menu {
    float: right;
    padding: 10px;
    position: absolute;
    right: 8px;
    top: 13px;
    display: none;
    .lnr {
      font-size: 34px;
    }
  }
}

@media screen and (max-width: $medium) {
  header {
    z-index: 123;
    .drop-user {
      display: none;
    }
    .toggle-menu {
      display: block;
    }
  }
  .open-menu {
    overflow: hidden;
    .toggle-menu {
      .lnr:before {
        content: "\e870";
      }
    }
  }
}
@media screen and (max-width: $small) {
  header {
    .drop-gymShark {
      min-width: 115px;
      width: 27%;
    }
  }
}