.clr {
  @extend %clearfix;
}
// Margin
.mgl-15 {
  margin-left: 15px;
}
.mgr-15 {
  margin-right: 15px;
}
.mgt-15 {
  margin-top: 15px;
}
.mgb-15 {
  margin-bottom: 15px;
}

.mgl-10 {
  margin-left: 10px;
}
.mgr-10 {
  margin-right: 10px;
}
.mgt-10 {
  margin-top: 10px;
}
.mgb-10 {
  margin-bottom: 10px;
}

.mgl-30 {
  margin-left: 30px;
}
.mgr-30 {
  margin-right: 30px;
}
.mgt-30 {
  margin-top: 30px;
}
.mgb-30 {
  margin-bottom: 30px;
}
// Padding
.pdt-0 {
  padding-top: 0!important;
}
.pdb-0 {
  padding-bottom: 0!important;
}
.pdt-30 {
  padding-top: 30px!important;
}
.pdb-30 {
  padding-bottom: 30px!important;
}
// FLoat
.f-l {
  float: left;
}
.f-r {
  float: right;
}
// Align
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-center {
  text-align: center;
}
// Border
.bb-0 {
  border-bottom: 0!important;
}
.bt-0 {
  border-top: 0!important;
}
@media screen and (max-width: $medium) {
  .hide-md {
    display: none!important;
  }
}