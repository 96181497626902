.login-page {
  background: url('../images/bg-login.jpg') no-repeat center;
  background-size: cover;
  height: 100vh;
}
.login-block {
  @include transforms(translate(-50%, -50%));
  @include flexbox();
  @include flex-wrap(wrap);
  @include align-items(center);
  max-width: 1070px;
  margin: 0 auto;
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  background: $color-primary;
  text-align: center;
  box-shadow: 6px 9px 28px #d6740f;
  .col {
    width: 50%;
    padding: 10px;
  }
  .login-form {
    background: #fff;
    padding: 110px 8% 100px;
    .title {
      font-size: 30px;
      margin-bottom: 70px;
      letter-spacing: -0.025em;
      font-family: $NHU-bold;
    }
    input {
      height: 55px;
      border-radius: 7px;
    }
  }
  p {
    a {
      text-decoration: underline;
      font-family: $NHU-bold;
      display: inline-block;
    }
  }
  .buttons-set {
    margin-bottom: 20px;
    padding: 0;
    .btn {
      width: 100%;
      height: 55px;
      border-radius: 7px;
      font-size: 18px;
    }
  }
}
@media screen and (max-width: $wide) {
  .login-block {
    .login-form {
      padding: 60px 5% 50px;
      .title {
        margin-bottom: 40px;
      }
    }
  }
}
@media screen and (max-width: $medium) {
  .login-block {
    position: static;
    transform: inherit;
    padding: 5px 10px 10px;
    .col {
      width: 100%;
    }
    .col-1 {
      img {
        max-width: 215px;
      }
    }
  }
  .login-page {
    #page-main {
      @include flexbox();
      @include align-items(center);
      height: 100vh;
    }
  }
}
@media screen and (max-width: $small) {
  .login-block {
    .login-form {
      padding: 30px 20px 30px;
      .title {
        font-size: 22px;
        margin-bottom: 29px;
      }
      input {
        height: 46px;
      }
    }
    .buttons-set .btn {
      height: 46px;
      margin: 0;
    }
  }
}