#sidebar {
  + #page-main {
    margin-left: 22.5%;
  }
}

.mm-menu {
  background: #fff;
  box-shadow: 0 0 11px #e8e8e8;
  top: 84px;
  &.mm-offcanvas {
    display: block;
    width: 22.5%;
    min-width: inherit;
    max-width: none;
  }
  #dropUser {
    display: none;
  }
  .drop-user {
    .img {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      width: 64px;
      border-bottom: 1px solid #e5e6e6;
      font-size: 20px;
      position: absolute;
      right: 100%;
      top: 0;
      bottom: -1px;
      height: auto;
      margin: 0;
      border-radius: 0;
      + .inner {
        display: block;
      }
      img {
        width: 35px;
      }
    }
    .toggle-dropdown {
      padding: 24px;
      strong {
        display: inline-block;
        margin: 0;
      }
      &::after {
        right: 17px;
      }
    }
    .content-dropdown {
      left: 0;
    }
  }
}
.mm-panels {
  background: none;
  >.mm-panel {
    padding-left: 84px;
    z-index: 3;
    background: url('../images/bg-icon-nav.png') repeat-y left #fff;

    // &:after {
    //   position: absolute;
    //   background: $color-gray;
    //   top: 0;
    //   left: 0;
    //   bottom: -100px;
    //   width: 64px;
    //   height: auto;
    //   z-index: 0;
    //   box-shadow: 0 0 11px #e8e8e8 inset;
    //   content: "";
    // }
    &.mm-hasnavbar {
      padding-top: 68px;
    }
  }
  .mm-next {
    &:before {
      display: none;
    }
  }
}
.mm-listview {
  position: relative;
  z-index: 2;
  > li {
    > a {
      padding: 24px;
      .lnr,
      .icon {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        width: 64px;
        border-bottom: 1px solid #e5e6e6;
        font-size: 20px;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: -1px;
        height: auto;
      }
    }
    &:after {
      display: none!important;
    }
  }
  .mm-next {
    left: 0;
    width: auto;
    &:after {
      left: auto!important;
      right: 7px!important;
    }
  }
  li {
    border-bottom: 1px solid $color-border;
  }
  a {
    .label {
      margin-left: 7px;
    }
    &:not(.mm-next):after {
      @include transition(all .4s);
      width: 6px;
      top: -1px;
      bottom: -1px;
      right: 0;
      background: $color-primary;
      content: "";
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }
    &:hover,
    &.active {
      &:not(.mm-next):after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.mm-next:after, .mm-prev:before {
  bottom: auto;
  content: "\e876";
  border: 0;
  width: auto;
  height: auto;
  font-family: Linearicons-Free;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50% !important;
  right: auto!important;
  line-height: normal;
}
.mm-navbar {
  padding: 0 0 0 64px;
  height: auto;
  border-color: $color-border;
  .mm-title {
    text-align: left;
    display: block;
    padding: 24px;
    color: #000!important;
    font-family: $NHU-bold;
  }
  .mm-btn {
    width: 64px;
    height: 69px;
    background: $color-primary;
    color: #fff;
  }
  .mm-prev:before {
    color: #fff;
    content: "\e875";
  }
}
#mm-0 {
  padding-top: 0;
  .mm-navbar {
    display: none;
  }
  .mm-listview {
    > li > a {
      font-family: $NHU-bold;
    }
  }
}

@media screen and (max-width: $widest + 1) {
  #sidebar {
    + #page-main {
      margin-left: 24%;
    }
  }
  .mm-menu {
    &.mm-offcanvas {
      width: 24%;
    }
  }
}
@media screen and (max-width: $wide) {
  .mm-menu {
    &.mm-offcanvas {
      width: 285px;
    }
  }
  #sidebar {
    + #page-main {
      margin-left: 285px;
    }
  }
}
@media screen and (max-width: $medium) {
  #sidebar {
    + #page-main {
      @include transition(opacity .4s);
      margin-left: 0;
      padding: 0 20px 30px;
    }
  }
  .mm-menu {
    &.mm-offcanvas {
      @include transforms(translateX(-100%));
      @include transition(transform .4s);
      width: auto;
      right: 0;
      z-index: 122;
      box-shadow: none;
    }
    #dropUser {
      display: block;
    }
  }
  .open-menu {
    .mm-menu {
      &.mm-offcanvas {
        @include transforms(translateX(0));
        box-shadow: 0 0 11px #e8e8e8;
      }
    }
    #page-main {
      opacity: 0;
    }
  }
}