input {
  &:not([type="checkbox"]),
  &:not([type="radio"]) {
    @include transition(border .4s);
    width: 100%;
    height: 36px;
    border: 1px solid $color-border-dark;
    padding: 0 13px;
    font-size: 14px;
    color: #222222;
    &:focus {
      border-color: $color-primary;
      outline: none;
    }
  }
}
.input-calendar {
  background: url('../images/icon-calendar.png') no-repeat right 10px center;
}
.input-box {
  margin-bottom: 16px;
  label {
    margin-bottom: 10px;
    display: block;
    font-family: $NHU-bold;
  }
  .select {
    width: 100%;
  }
}
.form-txt {
  font-size: 12px;
  color: #707070;
  margin-bottom: 0;
  padding-top: 5px;
}
.line-border {
  border-bottom: 1px solid $color-border;
  display: block;
}
.form-box {
  padding: 22px 20px;
  box-shadow: 0 3px 7px rgba(0,0,0,.15);
  .input-box {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .line-border {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.fields-2 {
  margin: 0 -10px;
  &::after {
    @extend %clearfix;
  }
  .input-box {
    width: 50%;
    padding: 0 10px;
    float: left;
  }
}
// Textarea 
textarea {
  border: 1px solid $color-border-dark;
  width: 100%;
  min-height: 120px;
  font-size: 14px;
  &:focus {
    border-color: $color-primary;
    outline: none;
  }
}

// Select
.select {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  select {
    border: 0;
    background: none;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    font-size: 14px;
    padding-right: 29px;
    border-radius: 0;
    &:focus {
      border-color: $color-primary;
      outline: none;
    }
  }
  &:after {
    @include transforms(translateY(-50%));
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    content: "\e874";
    font-family: $lnr;
    margin-top: 2px;
    pointer-events: none;
  }
  &.select-border {
    select {
      height: 36px;
      padding-left: 10px;
      padding-right: 48px;
      border: 1px solid $color-border-dark;
      font-size: 14px;
      font-family: $NHU-reg;
    }
    &:after {
      right: 14px;
    }
  }
  &.select-bold {
    select {
      font-family: $NHU-bold;
      font-size: 16px;
    }
  }
}
form {
  .line-border {
    margin-bottom: 18px;
  }
  .select {
    &.select-border {
      &:after {
        font-size: 10px;
        right: 10px;
      }
      &:before {
        @include transforms(translateY(-50%));
        position: absolute;
        height: 19px;
        border-left: 1px solid #e6e6e6;
        right: 30px;
        top: 50%;
        content: "";
      }
    }
  }
}
// Checkbox
.checkbox {
  padding-left: 27px;
  position: relative;
  cursor: pointer;
  span {
    font-family: $NHU-reg;
    &:before {
      width: 17px;
      height: 17px;
      border: 1px solid $color-border-dark;
      content: "";
      position: absolute;
      left: 0;
      top: -3px;
    }
    &:after {
      width: 9px;
      height: 9px;
      background: #000;
      content: "";
      left: 5px;
      top: 6px;
      position: absolute;
      opacity: 0;
    }
  }
  input {
    display: none;
    &:checked {
      + span {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
.buttons-set {
  padding: 30px 0;
}
.back-action {
  font-family: $NHU-bold;
  padding-bottom: 35px;
  .lnr {
    margin-right: 4px;
  }
}
@media screen and (max-width: $medium) {
  .back-action {
    padding: 25px 0 0;
  }
  .select.select-border select {
    padding-right: 38px;
  }
}
@media screen and (max-width: $small) {
  .buttons-set {
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .form-box .fields-2 {
    .input-box {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}