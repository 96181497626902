.list-oauth {
  @extend %clearfix;
  li {
    width: 33.3333333%;
    float: left;
    text-align: center;
    padding: 0 10px;
  }
}
.list-oauth-inline {
  li {
    display: inline-block;
    margin: 0 35px;
    text-align: center;
    img {
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: $widest) {
  .list-oauth-inline {
    font-size: 0;
    li {
      margin: 0;
      padding: 0 15px;
      width: 25%;
    }
  }
}
@media screen and (max-width: $small + 1) {
  .list-oauth li,
  .list-oauth-inline li {
    width: 50%;
  }
}